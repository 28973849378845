/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

@tailwind base;
@tailwind components;
@tailwind utilities;

a {
    @apply text-sky-800;
}

a:hover {
    @apply underline;
}

.heading-2 {
    @apply text-xl font-semibold text-gray-900;
}

.gradient-title {
    @apply text-3xl font-black text-transparent bg-clip-text bg-gradient-to-br from-slate-700 to-slate-800;
}

.ul {
    @apply list-disc ml-8;
}
